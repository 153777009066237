<template>
  	<table class="w-full table-fixed">
		<thead>
			<tr class="bg-gray-100 border-b border-gray-200 text-xs">
				<th class="text-left px-5 py-1">Trip Report</th>
				<th class="text-left w-3/12"><a class="px-5 py-1 block w-full cursor-pointer hover:bg-gray-200" @click="changeSort('rep_company.name')">Rep Company
					<ChevronDownIcon class="inline-block h-4 w-4 text-green" v-if="sort == 'rep_company.name' && sortDir == 'desc'" />
					<ChevronUpIcon class="inline-block h-4 w-4 text-green" v-if="sort == 'rep_company.name' && sortDir == 'asc'" />
				</a></th>
				<th class="text-left"><a class="px-5 py-1 block w-full cursor-pointer hover:bg-gray-200" @click="changeSort('start_date')">Start Date
					<ChevronDownIcon class="inline-block h-4 w-4 text-green" v-if="sort == 'start_date' && sortDir == 'desc'" />
					<ChevronUpIcon class="inline-block h-4 w-4 text-green" v-if="sort == 'start_date' && sortDir == 'asc'" />
				</a></th>
				<th class="text-left"><a class="px-5 py-1 block w-full cursor-pointer hover:bg-gray-200" @click="changeSort('end_date')">End Date
					<ChevronDownIcon class="inline-block h-4 w-4 text-green" v-if="sort == 'end_date' && sortDir == 'desc'" />
					<ChevronUpIcon class="inline-block h-4 w-4 text-green" v-if="sort == 'end_date' && sortDir == 'asc'" />
				</a></th>
				<th class="text-left">Sales Calls</th>
			</tr>
		</thead>
		<tbody>
			<tr class="border-b border-gray-200 text-sm" v-for="trip in allTrips" v-bind:key="trip.id">
				<td class="px-5 py-2"><router-link class="text-green hover:underline" :to="`/trips/${trip.id}`">View Trip Report</router-link></td>
				<td class="px-5 py-2">{{ trip.rep_company.name }}</td>
				<td class="px-5 py-2">{{ parseDate(trip.start_date) }}</td>
				<td class="px-5 py-2">{{ parseDate(trip.end_date) }}</td>
				<td class="px-5 py-2">{{ trip.sales_calls.length }}</td>
			</tr>
		</tbody>
	</table>
</template>

<script>
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/solid';

export default {
	components: { ChevronDownIcon, ChevronUpIcon },
	data: () => {
		return {
			sort: 'updated_at',
			sortDir: 'desc',
		}
	},

	props: {
		trips: Array,
	},

	computed: {
		allTrips() {
			let trips = this.trips;
			return trips.sort(this.compare);
		}
	},

	methods: {
		parseDate(date) {
			let d = new Date(date);
			return d.toLocaleDateString();
		},

		changeSort(sort = null) {
			if (!this.allTrips) return;
			if (this.sort === sort) {
				this.sortDir = this.sortDir == 'asc' ? 'desc' : 'asc';
			} else {
				this.sort = sort;
				this.sortDir = 'desc';
			}
			this.allTrips.sort(this.compare);
		},

		compare(a, b) {
			let aVal = a[this.sort];
			let bVal = b[this.sort];
			if (!isNaN(aVal) || !isNaN(bVal)) {
				aVal = aVal ? parseFloat(aVal) : 0;
				bVal = bVal ? parseFloat(bVal) : 0;
			}
			if (aVal > bVal) return (this.sortDir == 'asc' ? 1 : -1);
			if (aVal < bVal) return (this.sortDir == 'asc' ? -1 : 1);
			return 0;
		},
	}
};
</script>