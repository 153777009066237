<template>
  <div class="bg-gray-200">
    <div class="container mx-auto grid grid-cols-2 w-full">
      <div class="py-2 text-md">
        <router-link to="/" class="text-green hover:underline">Dashboard</router-link>
        <span class="text-gray-400"> / </span>
        <span class="font-bold">Trips</span>
      </div>
    </div>
  </div>
  <div class="container mx-auto py-5">
    <div class="shadow bg-gray-100 mb-5">
      <div class="px-5 py-2 border-b border-gray-200 bg-gray-50">
        <span class="font-bold">Trips</span>
      </div>
      <div v-if="trips.length > 0">
        <TripsTable :trips="trips" />
      </div>
      <div v-if="trips.length == 0 && !loadingTrips">
        <span class="block text-sm italic px-5 py-2 text-gray-400">No trips assigned to you</span>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import TripsTable from "@/components/TripsTable.vue";


export default {
  components: {
    TripsTable,
  },

  data: () => {
    return {
      loadingTrips: true,
    }
  },

  computed: {
    ...mapGetters({
        trips: 'trips/rows',
        user: 'user'
      }),
  },

  created: function () {
    document.title = 'MLE Rep Center | Trips';
    this.loadTrips();
  },

  methods: {
    async loadTrips() {
      this.loadingTrips = true;
      await this.$store.dispatch('trips/load');
      this.loadingTrips = false;
    },
  }
}
</script>